$testingColor: powderblue;
$dropdownColor: whitesmoke;
$signatureColor: blue;

//Services//
/* Title */
.section-title {
  position: flex;
  text-align: center;
  margin-bottom: 2rem;
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Playfair Display";
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: navy;
}
/* end of Title */
/* services */
.services {
  position: relative;
  padding: 2rem 0;
}
.services {
  text-align: center;
}
.services-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 3rem;
  grid-column-gap: 50px;
  justify-content: center;
  -ms-grid-row-align: center;
}
.service span {
  display: inline-block;
  color: #af9a7d;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  justify-content: center;
}

.services h6 {
  letter-spacing: 1px;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Playfair Display";
}
.services p {
  width: 80%;
  margin: 0 auto;
  font-family: "Playfair Display";
}
.lastOne {
  display: grid;
  justify-content: space-evenly;
  align-items: center;
  width: 90vw;
  margin-top: 3rem;
}
@media screen and (min-width: 992px) {
  .services-center {
    width: 80vw;
    max-width: 1170px;
  }
  .lastOne {
    width: 20vw;
    max-width: 390px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}
/*end of services */
