.error {
  width: 90vw;
  align-items: center;
  text-align: center;
  padding-bottom: 100px;
  h1 {
    font-size: 60px;
    color: darkred;
  }
  h2 {
    font-size: 40px;
    color: darkcyan;
  }
  p {
    font-size: 20px;
  }
}
.sadgif {
  padding-top: 100px;
  padding-bottom: 100px;
}
