$dropdownColor: whitesmoke;
$signatureColor: navy;

.slide-items {
  height: 700px;
  width: 100vw;
}

.img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.carousel-indicators {
  li {
    transition: none;
  }
  .active {
    background-color: $signatureColor;
  }
}

.carousel-control-next {
  height: 25px;
  right: 40px;
  top: 48%;
  width: auto;
}

.carousel-control-prev {
  height: 25px;
  left: 40px;
  top: 48%;
  width: auto;
}

.carousel-control-prev-icon {
  height: 25px;
  width: 25px;
  &:hover {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='navy ' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  }
}

.carousel-control-next-icon {
  height: 25px;
  width: 25px;
  &:hover {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='navy' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  }
}

//carousel slides container
.carousel-item {
  transition: transform 0.4s ease-in-out;
}
.d-none {
  display: block !important;
}
.text {
  margin-top: 100px;
  margin-bottom: 250px;
  background: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 800px) {
  .slide-items {
    height: 100%;
    width: 100%;
  }
  .text {
    margin-top: 0%;
    margin-bottom: 120px;
  }
  @media screen and (max-width: 590px) {
    .text {
      margin-top: 0%;
      margin-bottom: 50px;
    }
  }
}
