.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2rem 2rem;
  text-align: center;
  text-transform: capitalize;
}
.banner h1 {
  font-size: 2rem;
}
