//Footer//
.footer {
  position: absolute;
  background-image: linear-gradient(
    to bottom,
    rgb(6, 6, 56),
    rgb(4, 4, 97),
    rgb(31, 31, 190)
  );
  align-items: baseline;
  font-family: "Times New Roman", Times, serif;
  box-sizing: border-box;
  width: 100%;
}

.footerLogo {
  width: 200px;
}

.column {
  display: block;
  float: left;
  padding: 10px;
  width: 33.33%;
  color: lightblue;
  height: 200px;
  border-bottom: 1px solid white;
  p {
    color: white;
  }
}
.column2 {
  @extend .column;
  padding-top: 10px;
}
.footer:after {
  content: "";
  display: table;
  clear: both;
}
.footer-bottom {
  width: 100%;
  display: block;
  padding: 10px;
  margin-top: 200px;
  height: 100%;
}
.copyrightFooter {
  color: lightblue;
}
@media screen and (max-width: 500px) {
  .footer {
    width: 100vw;
    justify-content: center;
  }
  .column {
    width: 100%;
    height: 175px;
    border-bottom: none;
    justify-content: center;
  }
  .footer-bottom {
    border-top: 1px solid white;
    margin-top: 520px;
  }
}
